import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { BASE_URL } from '../../config';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import BookingModal from '../Booking Model/BookingModel';
const Navbar = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const timer = setInterval(() => {
      setModalIsOpen(true);
    }, 120000); // 5 seconds delay

    // Cleanup the timer if the component unmounts
    return () => clearTimeout(timer);
  }, []);
  const [menuOpen, setMenuOpen] = useState(false);
  const [aboutSubMenuOpen, setAboutSubMenuOpen] = useState(false);
  const [treatmentsSubMenuOpen, setTreatmentsSubMenuOpen] = useState(false);
  const [rulesSubMenuOpen, setRulesSubMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleAboutSubMenu = () => {
    setAboutSubMenuOpen(!aboutSubMenuOpen);
  };

  const toggleTreatmentsSubMenu = () => {
    setTreatmentsSubMenuOpen(!treatmentsSubMenuOpen);
  };

  const toggleRulesSubMenu = () => {
    setRulesSubMenuOpen(!rulesSubMenuOpen);
  };
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  async function fetchUserData() {
    const response = await fetch(BASE_URL + '/api/v1/routes/fetchAllLiinks');
    const data = await response.json();
    return data;
  }
  const { isLoading, isError, data, error } = useQuery('treatments', fetchUserData);
  if (isLoading) {
    return <div></div>;
  }

  if (isError) {
    return <div>Error: {error.message}</div>;
  }






  return (<>
    <BookingModal isOpen={modalIsOpen} onRequestClose={closeModal} />

    <nav className="bg-[#C03E43] w-full p-3 md:p-4 md:rounded-b-3xl z-40 fixed top-0">
      <div className=" flex justify-between  items-center">
        <div className="flex flex-col justify-center items-center">
          <Link to="/"><img src="../assets/logo-name.gif" alt="logo" className="md:w-[66px] w-12" /></Link>
          <img src="../assets/logo-name.png" alt="" className="w-20" />
        </div>
        <div className="hidden md:flex gap-9 text-[15px]">
          <li className="list-none">
            <Link to="/" className="text-gray-300 hover:text-[#F4A236]">Home</Link>
          </li>
          <li className="relative dropdown list-none">
            <Link to="#" className="text-gray-300 dropdown hover:text-[#F4A236]">
              About Us <i className="fa-solid fa-angle-down"></i>
            </Link>
            <ul className="absolute left-0 hidden w-48 bg-gray-800 dropdown-menu">
              {data.data.abouts.map((v, i) => {
                if (v.title == "Privacy Policy")
                  return <></>
                return <li key={i}><Link to={v.path} className="block px-4 py-2 text-white hover:bg-gray-700">{v.title}</Link></li>

              })}

            </ul>
          </li>
          <li className="list-none">
            <Link to={data.data.treatmentsSection[0].path} className="text-gray-300 hover:text-[#F4A236]">Treatment Available</Link>
          </li>
          <li className="relative dropdown list-none">
            <Link to="#" className="text-gray-300 hover:text-[#F4A236]">
              Treatments <i className="fa-solid fa-angle-down"></i>
            </Link>
            <ul className="absolute left-0 hidden w-48 bg-gray-800 dropdown-menu">
              {data.data.mainTreatments.map((v, i) => {
                return <li key={i}><Link to={v.path} className="block px-4 py-2 text-white hover:bg-gray-700">{v.title}</Link></li>

              })}

            </ul>
          </li>
          <li className="list-none">
            <Link to={data.data.plans[0].path} className="text-gray-300 hover:text-[#F4A236]">Indoor Treatment Plans</Link>
          </li>
          <li className="relative dropdown list-none">
            <Link to="#" className="text-gray-300 hover:text-[#F4A236]">
              Rules & Regulations <i className="fa-solid fa-angle-down"></i>
            </Link>
            <ul className="absolute left-0 hidden w-48 bg-gray-800 dropdown-menu">
              {data.data.ruleList.map((v, i) => {
                return <li key={i}><Link to={v.path} className="block px-4 py-2 text-white hover:bg-gray-700">{v.title}</Link></li>

              })}
            </ul>
          </li>
          <li className="list-none">
            <Link to="/blogs" className="text-gray-300 hover:text-[#F4A236]">Blog</Link>
          </li>
          <li className="list-none">
            <Link to={data.data.contact[0].path} className="text-gray-300 hover:text-[#F4A236]">Contact Us</Link>
          </li>


        </div>
        <div className='hidden md:block'>
          <button className="button-animate" onClick={(e) => { setModalIsOpen(true) }}>
            Online Consultation
          </button>
        </div>
        <div className='md:hidden' style={{ width: '20vw' }}>

        </div>
        <div className='md:hidden'>
          <button className="button-animate-mob" onClick={(e) => { setModalIsOpen(true) }} style={{ height: '5vh' }}>
            Online Consultation
          </button>
        </div>
        <div className="md:hidden">
          <button
            id="menu-btn"
            className="text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
            onClick={toggleMenu}
          >
            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>

          </button>
        </div>
      </div>
      <div id="menu" className={`${menuOpen ? 'block' : 'hidden'}`}>
        <Link to="/" onClick={toggleMenu} className="block text-gray-300 hover:text-[#F4A236] active:bg-[#872a33] p-2">Home</Link>
        <div className="relative">
          <button onClick={toggleAboutSubMenu} className="block w-full text-left text-gray-300 hover:text-[#F4A236] p-2 active:bg-[#872a33]">About Us  <i className="fa-solid fa-angle-down"></i></button>
          <ul className={`${aboutSubMenuOpen ? 'block' : 'hidden'} bg-gray-800`}>
            {data.data.abouts.map((v, i) => {
              if (v.title == "Privacy Policy")
                return <></>
              return <li key={i}><Link onClick={toggleMenu} to={v.path} className="block px-4 py-2 text-white hover:bg-gray-700">{v.title}</Link></li>

            })}
          </ul>
        </div>
        <Link to={data.data.treatmentsSection[0].path} className="text-gray-300 hover:text-[#F4A236]">Treatment Available</Link>

        <div className="relative">
          <button onClick={toggleTreatmentsSubMenu} className="block w-full text-left text-gray-300 hover:text-[#F4A236] p-2 active:bg-[#872a33]">Treatments  <i className="fa-solid fa-angle-down"></i></button>
          <ul className={`${treatmentsSubMenuOpen ? 'block' : 'hidden'} bg-gray-800`}>
            {data.data.mainTreatments.map((v, i) => {
              return <li key={i}><Link onClick={toggleMenu} to={v.path} className="block px-4 py-2 text-white hover:bg-gray-700">{v.title}</Link></li>

            })}
          </ul>
        </div>
        <Link to={data.data.plans[0].path} className="text-gray-300 hover:text-[#F4A236]">Indoor Treatment Plans</Link>

        <div className="relative">
          <button onClick={toggleRulesSubMenu} className="block w-full text-left text-gray-300 hover:text-[#F4A236] p-2 active:bg-[#872a33]">Rules & Regulations  <i className="fa-solid fa-angle-down"></i></button>
          <ul className={`${rulesSubMenuOpen ? 'block' : 'hidden'} bg-gray-800`}>
            {data.data.ruleList.map((v, i) => {
              return <li key={i}><Link onClick={toggleMenu} to={v.path} className="block px-4 py-2 text-white hover:bg-gray-700">{v.title}</Link></li>

            })}
          </ul>
        </div>
        <Link onClick={toggleMenu} to={"/blogs"} className="text-gray-300 hover:text-[#F4A236]">Blog</Link> <br></br>
        <Link onClick={toggleMenu} to={data.data.contact[0].path} className="text-gray-300 hover:text-[#F4A236]">Contact Us</Link>



        {/* <button className="button-animate" onClick={(e) => {
          toggleMenu()
          navigate("/contactus")
        }}>
          Online Consultation
        </button> */}
      </div>
    </nav>
  </>

  );
};

export default Navbar;
