import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
const Footer = () => {
  const navigate = useNavigate()
  return (
    <div className="footer bg-[#C03E43] text-white">
      <div className="md:flex justify-center p-10 md:p-24">
        {/* Location, Phone, Email */}
        <div className="flex flex-col gap-5 mb-8 md:mb-0 md:w-2/6 items-">
          <div className="flex items-center gap-3">
            <img src="../assets/home.png" alt="" />
            <p>Yogashray Sewayatan (Naturopathy & Yoga Meditation Centre)</p>
          </div>
          {/* Location */}
          <div className="flex items-center gap-3">
            <img src="../assets/location.png" alt="" />
            <p>Keshopura, Bhankrota, Ajmer Road, Jaipur</p>
          </div>
          {/* Phone */}
          <div className="flex items-center gap-3">
            <img src="../assets/phone.png" alt="" />
            <p>+919772947555 / +919772956555</p>
          </div>
          {/* Email */}
          <div className="flex items-center gap-3">
            <img src="../assets/mail.png" alt="" />
            <p>info@yogashraysewayatan.com</p>
          </div>
        </div>

        {/* Tripadvisor */}
        <div className="flex flex-col gap-5 mb-8 md:mb-0 md:w-2/6 md:items-center">
          <div className="flex flex-col gap-3">
            <div className="flex gap-3">
              <p>Tripadvisor</p>
              <img className='footericon' src="../assets/tripadvisor.png" alt="" />
            </div>
            <img src="../assets/stars.png" alt="" className="w-20" />
          </div>
          {/* Duplicate the Tripadvisor section */}

        </div>

        {/* Social Media Icons */}
        <div className="flex flex-col gap-5 mb-8 md:mb-0 md:w-2/6 md:items-center">
          <h1 className="text-xl">Follow Us On</h1>
          <div className="flex gap-3 md:ml-20">
            <a href='https://www.facebook.com/yogashraysewayatan/'>    <img className='footericon' src="../assets/facebook.png" alt="" /></a>
            <a href='https://www.instagram.com/yogashraysewayatan/'>  <img className='footericon' src="../assets/instagram.png" alt="" /></a>
            <a href='https://wa.me/9772398555'> <img className='footericon' src="../assets/whatsapp.png" alt="" /></a>
            <a href='https://www.youtube.com/channel/UCrLtb_GTc8OfQXMseTCaH4w/videos'><img className='footericon' src="../assets/youtube.png" alt="" /></a>
            <a href='https://www.tripadvisor.in/Attraction_Review-g304555-d14047272-Reviews-Yogashray_Sewayatan-Jaipur_Jaipur_District_Rajasthan.html'> <img className='footericon' src="../assets/tripadvisor.png" alt="" /></a>
          </div>
        </div>
      </div>

      {/* Privacy Policy */}
      <div className="bg-black/45 p-5">
        <Link to="/privacy-policy"> <p className="text-center" >Privacy Policy</p>
        </Link>
      </div>
    </div>
  );
};

export default Footer;
